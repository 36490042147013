/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "p-4",
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": ""
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("b-alert", { attrs: { show: _vm.alert.show, variant: "warning" } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "9", offset: "3" } },
            [
              _c("h2", { staticClass: "heading tw-mb-2 tw-text-2xl" }, [
                _vm._v(" Unpause or Reschedule Prescription ")
              ]),
              _c("p", { staticClass: "tw-text-muted tw-text-base" }, [
                _vm._v(
                  " Do you want to unpause this prescription or to reschedule? "
                )
              ]),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", [
                      _c("span", { staticClass: "text-primary text-small" }, [
                        _vm._v("Patient")
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.rowData.firstname) +
                            " " +
                            _vm._s(_vm.rowData.lastname)
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", [
                      _c("span", { staticClass: "text-primary text-small" }, [
                        _vm._v("Prescription")
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.rowData.medicinename) +
                            " | " +
                            _vm._s(_vm.rowData.dosage)
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { offset: "3" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "secondary",
                            block: "",
                            pill: "",
                            disabled: _vm.buttonDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "open-pause-prescription",
                                _vm.rowData
                              )
                            }
                          }
                        },
                        [_vm._v(" Reschedule ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-dark",
                            block: "",
                            pill: "",
                            disabled: _vm.buttonDisabled
                          },
                          on: { click: _vm.resumePrescription }
                        },
                        [_vm._v(" Unpause ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }